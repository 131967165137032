import ReactOnRails from 'react-on-rails';
// TODO: The regenerator runtime library is required to support async/await, but it really shouldn't
// be necessary with our babel setup. Async/await is an ES2016 feature, and it should be provided
// with the @babel/preset-env plugin with target set to node 10. But alas, I'm seeing an error in
// compiled code. We can solve it by simply importing the library here, but 3 brownie points to
// whoever can update our config so that it's not required 🍪🍪🍪
import 'regenerator-runtime/runtime';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import ApiAuthenticationExample from '../components/ApiAuthenticationExample/entry';
import ApiExplorer from '../components/ApiExplorer/ApiExplorer';
import ApiKeyManager from '../components/ApiKeyManager/entry';
import ContentReplacementForm from '../components/ContentReplacementForm/entry';
import EnableMyShopSwitch from '../components/EnableMyShopSwitch/entry';
import ImageSlider from '../components/ImageSlider/entry';
import ConfirmationView from '../components/IosWidgetSdkDocs/ConfirmationView';
import Initialization from '../components/IosWidgetSdkDocs/Initialization';
import Manual from '../components/IosWidgetSdkDocs/Manual';
import MultiOptInView from '../components/IosWidgetSdkDocs/MultiOptInView';
import OptInView from '../components/IosWidgetSdkDocs/OptInView';
import SwiftPackageManager from '../components/IosWidgetSdkDocs/SwiftPackageManager';
import AndroidGradleDependencies from '../components/AndroidWidgetSdkDocs/AndroidGradleDependencies';
import LoadingIndicator from '../components/LoadingIndicator';
import AndroidInitialization from '../components/AndroidWidgetSdkDocs/AndroidInitialization';
import AndroidOptInView from '../components/AndroidWidgetSdkDocs/AndroidOptInView';
import AndroidMultiOptInView from '../components/AndroidWidgetSdkDocs/AndroidMultiOptInView';
import AndroidConfirmationView from '../components/AndroidWidgetSdkDocs/AndroidConfirmationView';

ReactOnRails.register({
  ApiKeyManager,
  ApiExplorer,
  ApiAuthenticationExample,
  ContentReplacementForm,
  EnableMyShopSwitch,
  ImageSlider,
  SwiftPackageManager,
  Manual,
  Initialization,
  LoadingIndicator,
  OptInView,
  MultiOptInView,
  ConfirmationView,
  AndroidGradleDependencies,
  AndroidInitialization,
  AndroidOptInView,
  AndroidMultiOptInView,
  AndroidConfirmationView,
});
