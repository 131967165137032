// Here we show a cURL sample that is actually executable via copy & paste.
//
// Prettier likes to format in the pre / code tag - in this case that's not helpful. Unfortunately
// we can't disable and re-enable line-by-line so we settle for disabling for this whole file.
import React from 'react'

// prettier-ignore
export default function CurlSample({ url, appId, signature, query }) {
  return <pre className="highlight">
    <code className="bash">
      curl --request POST '{url}' \<br/>
      {'  '}--header 'X-AIRROBE-APP-ID: {appId}' \<br/>
      {'  '}--header 'X-AIRROBE-HMAC-SHA256: {signature}' \<br/>
      {'  '}--header 'Content-Type: application/json' \<br/>
      {'  '}--data-raw '{query}'
    </code>
  </pre>
}
