export default `query CheckOrder($exampleOrderId: String!) {
  order(id: $exampleOrderId) {
    id
    customer {
      givenName
      familyName
      email
    }
    lineItems {
      title
      paidPrice {
        cents
        currency
      }
    }
  }
}`;
