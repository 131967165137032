import React from "react";
import Slider from "react-slick";

interface Props {
  images: Array<string>;
}

// A very simple slider :)
export default function ImageSlider({ images }: Props) {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Slider {...settings}>
      {images.map((image) => (
        <div>
          <img src={image}></img>
        </div>
      ))}
    </Slider>
  );
}
