import { useMutation } from "@apollo/client";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import Turbolinks from "turbolinks";
import LoadingIndicator from "../LoadingIndicator";
import ENABLE_MY_SHOP_MUTATION from "./mutations/EnableMyShopMutation";
import { EnableMyShop } from "./mutations/types/EnableMyShop";

interface Props {
  enabled: boolean;
  approved: boolean;
}

// TODO: let's put this into our material UI theme
const brandColor = "#FF9579";

export default function EnableMyShopSwitch({ enabled, approved }: Props) {
  const [isOn, setIsOn] = useState(enabled);
  const classes = useStyles();
  const [derp, setDerp] = useState(false);
  const [enable, { loading, data }] = useMutation<EnableMyShop>(
    ENABLE_MY_SHOP_MUTATION,
    { variables: { input: {} } }
  );

  function handleWrapperClick() {
    if (!approved) setDerp(true);
  }

  useEffect(() => {
    if (isOn) enable();
  }, [isOn]);

  useEffect(() => {
    if (data && data.enableMyShop.success)
      Turbolinks.visit(location.toString());
  }, [data]);

  const showLoadingIndicator = loading || data?.enableMyShop?.success;

  return (
    // If the user tries to click the switch while disabled, set 'derp' to true, showing an error
    <label onClick={handleWrapperClick}>
      <Switch
        disabled={!approved}
        onColor={brandColor}
        uncheckedIcon={
          <span className={classes.icon} uk-icon="icon: close;"></span>
        }
        checkedIcon={
          <span className={classes.icon} uk-icon="icon: check;"></span>
        }
        onChange={() => setIsOn((e) => !e)}
        checked={isOn}
        className={derp ? classes.shake : null}
      />
      {derp ? (
        <div className={classes.fadeIn}>
          <small>Sorry, you can't enable airrobe yet!</small>
        </div>
      ) : null}
      {showLoadingIndicator ? <LoadingIndicator width={54} /> : null}
    </label>
  );
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  label: {
    display: "inline",
    margin: 0,
    marginRight: 10,
  },
  icon: {
    margin: 8,
    color: "white",
  },
  fadeIn: {
    animation: "$fadeIn 1s normal forwards",
  },
  shake: {
    animation: "$shake 0.5s normal forwards",
  },
  loadingIndicator: {
    position: "fixed",
    background: "#000",
    opacity: 0.5,
    top: 0,
    left: 0,
    height: "100vh",
    width: "100vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    animation: "$fadeIn30 1s normal forwards",
  },
  "@keyframes fadeIn": {
    "0%": {
      opacity: "0%",
    },
    "100%": {
      opacity: "100%",
    },
  },
  "@keyframes fadeIn30": {
    "0%": {
      opacity: "0%",
    },
    "100%": {
      opacity: "30%",
    },
  },
  "@keyframes shake": {
    "10%, 90%": {
      transform: "translate3d(-1px, 0, 0)",
    },

    "20%, 80%": {
      transform: "translate3d(2px, 0, 0)",
    },

    "30%, 50%, 70%": {
      transform: "translate3d(-4px, 0, 0)",
    },

    "40%, 60%": {
      transform: "translate3d(4px, 0, 0)",
    },
  },
});
