import { useLazyQuery, useMutation } from "@apollo/client";
import { debounce } from "lodash";
import React, {
  ChangeEvent,
  SyntheticEvent,
  useEffect,
  useRef,
  useState
} from "react";
import Turbolinks from "turbolinks";
import Example from "./Example";
import { UpsertContentReplacement } from "./mutations/types/UpsertContentReplacement";
import MUTATION from "./mutations/UpsertContentReplacement";
import PREVIEW_QUERY from "./queries/GetContentReplacementPreview";

interface Props {
  id: number;
  target: string;
  replacement: string;
  shopId: number;
  redirectPathAfterSave: string;
}

export default function ContentReplacementForm({
  target: initialTarget = "",
  replacement: initialReplacement = "",
  shopId,
  redirectPathAfterSave,
}: Props) {
  const [target, setTarget] = useState(initialTarget);
  const [replacement, setReplacement] = useState(initialReplacement);
  const [previewQuery, { data: previewData }] = useLazyQuery(PREVIEW_QUERY, {
    onCompleted: () => setPreviewLoading(false),
  });
  const [isPreviewLoading, setPreviewLoading] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const debouncedQuery = useRef(debounce(previewQuery, 1000)).current;
  const [mutation] = useMutation<UpsertContentReplacement>(MUTATION, {
    onCompleted: (data) => {
      Turbolinks.visit(redirectPathAfterSave);
    },
  });

  const submit = (event: SyntheticEvent) => {
    event.preventDefault();
    setSubmitting(true);
    mutation({ variables: { input: { shopId, target, replacement } } });
  };

  useEffect(() => {
    setPreviewLoading(true);
    debouncedQuery({ variables: { shopId, target, replacement } });
  }, [target, replacement]);

  const handleTargetChange = (event: ChangeEvent<HTMLTextAreaElement>) =>
    setTarget(event.target.value);

  const handleReplacementChange = (event: ChangeEvent<HTMLTextAreaElement>) =>
    setReplacement(event.target.value);

  function getPreview() {
    if (isPreviewLoading) return "Loading...";

    if (previewData?.contentReplacementPreview?.exampleContentAfter)
      return <Example {...previewData?.contentReplacementPreview} />;

    return "No listings match this text";
  }

  return (
    <form>
      <div className="uk-margin">
        <label className="uk-form-label">Target text</label>
        <textarea
          className="uk-input"
          value={target}
          onChange={handleTargetChange}
        />
      </div>

      <div className="uk-margin">
        <label className="uk-form-label">Replacement text</label>
        <textarea
          className="uk-input"
          value={replacement}
          onChange={handleReplacementChange}
        />
      </div>
      <button className="uk-button uk-button-primary" onClick={submit}>
        {isSubmitting ? "Saving..." : "Save"}
      </button>
      <div className="uk-margin uk-card uk-card-default uk-card-body">
        <h3 className="uk-card-title">Example listing</h3>
        {getPreview()}
      </div>
    </form>
  );
}
