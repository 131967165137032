import React from "react";

export type Mode = "sandbox" | "production";

interface Props {
  selectedMode: Mode;
}

// This form reloads the page with a "mode" param when the select box is changed.
export default function ModeSelector({ selectedMode }: Props) {
  return (
    <form
      action={window.location.pathname}
      method="get"
      style={{ display: "flex" }}
    >
      <select
        className="uk-select toolbar-button"
        name="mode"
        defaultValue={selectedMode}
        onChange={(event) =>
          (event.target.parentElement as HTMLFormElement).submit()
        }
      >
        <option value="sandbox">Sandbox mode</option>
        <option value="production">Production mode</option>
      </select>
    </form>
  );
}
