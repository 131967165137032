import React from "react";

interface Props {
  secret: string;
  requestBody: string;
}

// TODO: add proper syntax highlighting using the highlight.js library. It wasn't playing nice for
// me.
export default function NodeExample({ secret, requestBody }: Props) {
  return (
    <div className="language-js highlighter-rouge">
      <div className="highlight">
        <pre className="highlight">
          <code>
            <div>{`const crypto = require("crypto");`}</div>
            <div>{` `}</div>
            <div>{`const secretKey = "${secret}";`}</div>
            <div>{`const requestBody = '${requestBody}';`}</div>
            <div>{` `}</div>
            <div>{`const signature = crypto`}</div>
            <div>{`  .createHmac("SHA256", secretKey)`}</div>
            <div>{`  .update(Buffer.from(requestBody, "utf8"))`}</div>
            <div>{`  .digest("base64");`}</div>
          </code>
        </pre>
      </div>
    </div>
  );
}
