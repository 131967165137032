import gql from "graphql-tag";

export default gql`
  mutation UpsertContentReplacement(
    $input: UpsertContentReplacementMutationInput!
  ) {
    upsertContentReplacement(input: $input) {
      success
    }
  }
`;
