import React from 'react'

// prettier-ignore
export default function OptInView() {
  return (
    <pre className='highlight'>
      <code className='swift'>
      var airRobeOptIn = AirRobeOptIn()<br/>
      <br/>
      airRobeOptIn.initialize(<br/>
      {'  '}brand: "",<br/>
      {'  '}material: "",<br/>
      {'  '}category: "Hats/fancy-hats",<br/>
      {'  '}priceCents: "100",<br/>
      {'  '}originalFullPriceCents: "100",<br/>
      {'  '}rrpCents: "100",<br/>
      {'  '}currency: "AUD",<br/>
      {'  '}locale: "en-AU"<br/>
      )<br/>
      </code>
    </pre>
  )
}
