import React from 'react'

// prettier-ignore
export default function ConfirmationView() {
  return (
    <pre className='highlight'>
      <code className='swift'>
      var airRobeConfirmation = AirRobeConfirmation()<br/>
      ...<br/>
      airRobeConfirmation.initialize(<br/>
      {'  '}orderId: "123456"<br/>
      {'  '}orderSubtotalCents: "11000",<br/>
      {'  '}currency: "AUD",<br/>
      {'  '}email: "test@email.com"<br/>
      )<br/>
      </code>
    </pre>
  )
}
