// The 'fetcher' is used by graphiql to fetch data. We return a function after passing an endpoint,
// appId, and secretKey.

import hmacSignature from "../../../utils/hmacSignature";

interface Args {
  endpoint: string;
  appId: string;
  secretKey: string;
}

export default function buildFetcher({ endpoint, appId, secretKey }: Args) {
  return function fetcher(params: any) {
    const body = JSON.stringify(params);

    return fetch(endpoint, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-AIRROBE-APP-ID": appId,
        "X-AIRROBE-HMAC-SHA256": hmacSignature({ secretKey, data: body }),
      },
      body,
    })
      .then((response) => response.text())
      .then((responseBody) => {
        try {
          return JSON.parse(responseBody);
        } catch (e) {
          return responseBody;
        }
      });
  };
}
