import { merge } from "lodash";

// Be sure to namespace variables with something globally unique, as they are merged together.
import { query as q1, variables as v1 } from "./createOrder";
import { query as q2, variables as v2 } from "./bulkCreateOrders";
import { query as q3, variables as v3 } from "./checkOrder";
import { query as q4, variables as v4 } from "./getLineItemsEligibility";
import { query as q5, variables as v5 } from "./checkIsCustomer";

const query = [q1, q2, q3, q4, q5].join("\n");
const variables = merge({}, v1, v2, v3, v4, v5);

export { query, variables };
