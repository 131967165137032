import React from "react";

interface Props {
  exampleContentBefore: string;
  exampleContentAfter: string;
}
export default function Example({
  exampleContentBefore,
  exampleContentAfter,
}: Props) {
  return (
    <div uk-grid className="uk-grid uk-child-width-expand@s">
      <div>
        <h5>Before</h5>
        <div
          dangerouslySetInnerHTML={{
            __html: exampleContentBefore,
          }}
        ></div>
      </div>

      <div>
        <h5>After</h5>
        <div
          dangerouslySetInnerHTML={{
            __html: exampleContentAfter,
          }}
        ></div>
      </div>
    </div>
  );
}
