import { makeStyles } from "@material-ui/styles";
import React from "react";
import Spinner, { Props } from "./Spinner";

export default function LoadingIndicator(props: Props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Spinner width={54} {...props} />
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    position: "fixed",
    background: "#000",
    opacity: 0.5,
    top: 0,
    left: 0,
    height: "100vh",
    width: "100vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    animation: "$fadeIn30 1s normal forwards",
  },
  "@keyframes fadeIn30": {
    "0%": {
      opacity: "0%",
    },
    "100%": {
      opacity: "50%",
    },
  },
});
