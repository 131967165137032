import React from 'react';

// prettier-ignore
export default function AndroidGradleDependencies() {
  return (
    <pre className='highlight'>
      <code className='swift'>
        dependencies: &#123;<br/>
          {'   '}//Other dependencies are here<br/>
          {'   '}implementation project(':AirRobeWidget')
        <br/>&#125;
      </code>
    </pre>
  )
}
