import gql from "graphql-tag";

export default gql`
  mutation RevokeApiKey($input: RevokeApiKeyMutationInput!) {
    revokeApiKey(input: $input) {
      apiKey {
        id
        isRevoked
      }
    }
  }
`;
