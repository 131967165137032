import gql from "graphql-tag";

export default gql`
  query GetShopApiKeys {
    shop {
      apiKeys {
        id
        secretKey
        isRevoked
        createdAtSeconds
      }
    }
  }
`;
