import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createElement } from "react";

const httpLink = createHttpLink({
  uri: "/graphql",
});

const authLink = setContext((_operation, previousContext) => ({
  headers: {
    ...previousContext.headers,
    ...(window.shopifySessionToken && {
        authorization: `Bearer ${window.shopifySessionToken}`
      })
  },
}));

export const apolloClient = new ApolloClient({
  ssrMode: process.env.NODE_ENV === "production",
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});

export const withApollo = (children: any) =>
  createElement(ApolloProvider, { client: apolloClient, children });
