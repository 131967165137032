import GraphiQL from "graphiql";
import GraphiQLExplorer from "graphiql-explorer";
import "graphiql/graphiql.css";
import {
  buildClientSchema,
  getIntrospectionQuery,
  GraphQLSchema,
} from "graphql";
import React, { useEffect, useRef, useState } from "react";
import "./ApiExplorer.css";
import { getDefaultScalarArgValue, makeDefaultArg } from "./CustomArgs";
import ModeSelector, { Mode } from "./ModeSelector";
import buildFetcher from "./utils/fetcher";
import {
  query as exampleQuery,
  variables as exampleVariables,
} from "./examples";

// This component renders an interactive explorer for our GraphQL API, with the ability to sign
// requests with an AppID a secret key. We publish the AppId and SecretKey of a dummy sandbox shop
// here.
interface Props {
  endpoint: string;
  secretKey: string;
  appId: string;
  mode: Mode;
}

function ApiExplorer({ endpoint, secretKey, appId, mode }: Props) {
  const graphiQLRef = useRef<GraphiQL>(null); // This ref points to the instance of graphiql
  const prettyVariables = JSON.stringify(exampleVariables, null, 2);

  const [schema, setSchema] = useState<GraphQLSchema | null>(null);
  const [query, setQuery] = useState(exampleQuery);
  const [isExplorerOpen, setExplorerOpen] = useState(true);

  console.log({ exampleVariables });

  const toggleIsExplorerOpen = () => setExplorerOpen(!isExplorerOpen);

  // Build a fetcher function using the endpoint passed in
  const fetcher = useRef(buildFetcher({ endpoint, secretKey, appId })).current;

  // Set the schema in state after making an introspection query against the endpoint
  useEffect(() => {
    fetcher({
      query: getIntrospectionQuery(),
    }).then(({ data }) => setSchema(buildClientSchema(data)));
  }, []);

  return (
    <div className="explorer-container graphiql-container">
      <GraphiQLExplorer
        schema={schema}
        query={query}
        onEdit={setQuery}
        onRunOperation={(operationName) =>
          graphiQLRef.current.handleRunQuery(operationName)
        }
        explorerIsOpen={isExplorerOpen}
        onToggleExplorer={toggleIsExplorerOpen}
        getDefaultScalarArgValue={getDefaultScalarArgValue}
        makeDefaultArg={makeDefaultArg}
      />
      <GraphiQL
        ref={graphiQLRef}
        fetcher={fetcher}
        schema={schema}
        query={query}
        variables={prettyVariables}
        onEditQuery={setQuery}
      >
        <GraphiQL.Toolbar>
          <ModeSelector selectedMode={mode} />
          <GraphiQL.Button
            onClick={() => graphiQLRef.current.handlePrettifyQuery()}
            label="Prettify"
            title="Prettify Query (Shift-Ctrl-P)"
          />
          <GraphiQL.Button
            onClick={() => graphiQLRef.current.handleToggleHistory()}
            label="History"
            title="Show History"
          />
          <GraphiQL.Button
            onClick={toggleIsExplorerOpen}
            label="Explorer"
            title="Toggle Explorer"
          />
        </GraphiQL.Toolbar>
      </GraphiQL>
    </div>
  );
}

export default ApiExplorer;
