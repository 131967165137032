import React from 'react';

// prettier-ignore
export default function AndroidMultiOptInView() {
  return (
    <pre className='highlight'>
      <code className='swift'>
      val airRobeMultiOptIn = findViewById&lt;AirRobeMultiOptIn&gt;(R.id.multi_opt_in_widget)<br/>
      ...<br/>
      airRobeMultiOptIn.initialize(<br/>
      {'  '}arrayOf("Accessories", "Accessories")<br/>
      </code>
    </pre>
  )
}
