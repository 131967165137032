import React from 'react';

interface Props {
  appId: string;
  mode: string;
}

// prettier-ignore
export default function AndroidInitialization({
  appId, mode
}: Props) {
  return (
    <pre className='highlight'>
      <code className='swift'>
      import com.airrobe.widgetsdk.airrobewidget.AirRobeWidget<br/>
      import com.airrobe.widgetsdk.airrobewidget.config.AirRobeWidgetConfig<br/>
      import com.airrobe.widgetsdk.airrobewidget.config.Mode<br/><br/>
      AirRobeWidget.initialize(<br/>
      {'  '}AirRobeWidgetConfig(<br/>
        {'  '}{'  '}appId: "{`${appId || 'example-app-id'}`}",<br/>
        {'  '}{'  '}color: "ff0000",<br/>
        {'  '}{'  '}mode: "{`${mode || 'Mode.PRODUCTION'}`}"<br/>
        {'  '})<br/>
        )
      </code>
    </pre>
  )
}
