import { useMutation } from "@apollo/client";
import classNames from "classnames";
import moment from "moment";
import React, { useState } from "react";
import REVOKE_API_KEY_MUTATION from "../../mutations/RevokeApiKeyMutation";
import {
  RevokeApiKey,
  RevokeApiKeyVariables,
} from "../../mutations/types/RevokeApiKey";
import { GetShopApiKeys_shop_apiKeys } from "../../queries/types/GetShopApiKeys";
import css from "./ApiKeyRow.module.css";

interface Props {
  apiKey: GetShopApiKeys_shop_apiKeys;
}

const REVOCATION_WARNING = `WARNING

You are about to revoke an active API key.

This will cause all future requests using this key to fail.

Are you sure you want to do this?
`;

export default function ApiKeyRow({ apiKey }: Props) {
  const { id, isRevoked, secretKey, createdAtSeconds } = apiKey;
  const [isPrivateKeyDisplayed, setPrivateKeyDisplayed] = useState(false);

  const [revoke, { error }] = useMutation<RevokeApiKey, RevokeApiKeyVariables>(
    REVOKE_API_KEY_MUTATION,
    {
      variables: { input: { id } },
    }
  );

  const createdAt = moment.unix(createdAtSeconds);

  return (
    <li
      className={classNames(css.root, "t-apiKey", { [css.revoked]: isRevoked })}
    >
      <div className={css.details}>
        <div>
          <label className={css.label}>Issued:</label>
          <em className={isRevoked ? css.revoked : null}>
            {createdAt.format("MMMM Do YYYY")}
          </em>
        </div>
        <div>
          <label className={css.label}>Secret key:</label>
          <span>
            <em className={isRevoked ? css.revoked : null}>
              {isPrivateKeyDisplayed
                ? secretKey
                : partiallyObsucre(secretKey, 4)}
            </em>
          </span>
        </div>
      </div>
      <div className={css.action}>
        {!isRevoked ? (
          <button
            className="uk-button uk-button-default"
            onClick={() => setPrivateKeyDisplayed(true)}
            disabled={isPrivateKeyDisplayed}
          >
            Reveal
          </button>
        ) : null}
      </div>
      <div className={css.action}>
        <button
          className="uk-button uk-button-danger"
          onClick={() => confirm(REVOCATION_WARNING) && revoke()}
          disabled={isRevoked}
        >
          {isRevoked ? "(Revoked)" : "Revoke"}
        </button>
      </div>
    </li>
  );
}

function partiallyObsucre(string, numberOfVisibleCharacters) {
  const obscuringCharacter = "*";
  const obscuredCharacterCount = string.length - numberOfVisibleCharacters;

  return (
    obscuringCharacter.repeat(obscuredCharacterCount) +
    string.slice(string.length - numberOfVisibleCharacters)
  );
}
