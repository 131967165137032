import { Buffer } from "buffer";
import crypto from "crypto-browserify";

interface Args {
  data: string;
  secretKey: string;
}
export default function hmacSignature({ data, secretKey }: Args) {
  return crypto
    .createHmac("SHA256", secretKey)
    .update(new Buffer(data, "utf8"))
    .digest("base64");
}
