import { useMutation, useQuery } from "@apollo/client";
import { sortBy } from "lodash";
import React from "react";
import ApiKeyRow from "./components/ApiKeyRow/ApiKeyRow";
import ISSUE_API_KEY_MUTATION from "./mutations/IssueApiKeyMutation";
import { IssueApiKey } from "./mutations/types/IssueApiKey";
import GET_SHOP_API_KEYS from "./queries/GetShopApiKeys";
import { GetShopApiKeys } from "./queries/types/GetShopApiKeys";

const ISSUE_KEY_WARNING = `Are you sure you want to issue a new API key?`;

export default function ApiKeyManager() {
  const { loading, data, error: queryError } = useQuery<GetShopApiKeys>(
    GET_SHOP_API_KEYS
  );
  const [issueKey, { error: mutationError }] = useMutation<IssueApiKey>(
    ISSUE_API_KEY_MUTATION,
    // The mutation doesn't take any input, but after completion, we refetch all ApiKeys
    { variables: { input: {} }, refetchQueries: [{ query: GET_SHOP_API_KEYS }] }
  );

  const error = queryError || mutationError;

  if (loading) return <>Loading...</>;
  if (error) return <>Sorry, something went wrong: {error.message}</>;
  if (!data) return null;

  const {
    shop: { apiKeys },
  } = data;

  return (
    <>
      <ul>
        {sortBy(apiKeys, "isRevoked").map((apiKey) => (
          <ApiKeyRow key={apiKey.id} apiKey={apiKey} />
        ))}
      </ul>
      <button
        className="uk-button uk-button-primary"
        onClick={() => confirm(ISSUE_KEY_WARNING) && issueKey()}
      >
        Issue new key
      </button>
    </>
  );
}
