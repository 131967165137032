import React from 'react'

// prettier-ignore
export default function Manual() {
  return (
    <pre className='highlight'>
      <code className='bash'>
        git submodule add https://github.com/airrobe/widget-sdk-ios.git AirRobeWidget<br/>
        cd AirRobeWidget<br/>
        git checkout 1.0.0<br/>
      </code>
    </pre>
  )
}
