import React from 'react'

// prettier-ignore
export default function SwiftPackageManager() {
  return (
    <pre className='highlight'>
      <code className='swift'>
        dependencies: [<br/>
          {'   '}.package(url: "https://github.com/airrobe/widget-sdk-ios.git",<br/>
          {'   '}.upToNextMajor(from: "1.0.0"))
        <br/>]
      </code>
    </pre>
  )
}
