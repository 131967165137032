import gql from "graphql-tag";

// Given some target text and replacement text, provide a preview of a matching line item
export default gql`
  query GetContentReplacementPreview($shopId: Int!, $target: String!, $replacement: String!) {
    contentReplacementPreview(shopId: $shopId, target: $target, replacement: $replacement) {
      exampleContentBefore
      exampleContentAfter
    }
  }
`