import React, { useEffect, useState } from "react";
import hmacSignature from "../../utils/hmacSignature";
import CurlSample from "./CurlSample";
import NodeExample from "./NodeExample";
import RubyExample from "./RubyExample";

interface Props {
  appId: string;
  endpoint: string;
}

// This component provides an interactive example of how to work with our authentication system. The
// endpoint can be passed in, but defaults to /graphql on the current host. It would probably be
// better to always pass a prop here, and handle the default outside of this components, but it
// ended up a lot simpler to tackle it this way.
export default function ApiAuthenticationExample({
  appId: initialAppId,
  endpoint: initialEndpoint,
}: Props) {
  const [appId, setAppId] = useState(initialAppId);
  const [secretKey, setSecretKey] = useState("");
  const [endpoint, setEndpoint] = useState(
    initialEndpoint || window.location.origin + "/graphql"
  );
  const [signature, setSignature] = useState(null);
  const [query, setQuery] = useState(
    '{"query":"query HelloWorld { hello }","variables":{}}'
  );

  // After the local state changes, recalculate the signature
  useEffect(() => {
    setSignature(secretKey ? hmacSignature({ secretKey, data: query }) : null);
  }, [secretKey, query]);

  // The markup for these components is super rough, with raw UiKIT HTML just dropped in. In future
  // we may like to properly extract presentational components for things like forms, tab components
  // etc. This is fine for now.
  return (
    <form className="uk-margin-medium-top">
      <fieldset className="uk-fieldset">
        <legend className="uk-legend">Endpoint</legend>
        <div className="uk-margin">
          <input
            value={endpoint}
            onChange={(event) => setEndpoint(event.target.value)}
            className="uk-input"
            placeholder="Enter your sandbox or production endpoint"
          />
        </div>
      </fieldset>
      <div className="uk-grid-small" uk-grid>
        <div className="uk-inline uk-width-1-2@s">
          <legend className="uk-legend">App ID</legend>
          <div className="uk-margin">
            <input
              value={appId}
              onChange={(event) => setAppId(event.target.value)}
              className="uk-input"
              type="text"
              placeholder="Enter your App ID here"
            />
          </div>
        </div>
        <div className="uk-inline uk-width-1-2@s">
          <legend className="uk-legend">Secret key</legend>

          <div className="uk-margin">
            <input
              value={secretKey}
              onChange={(event) => setSecretKey(event.target.value)}
              className="uk-input"
              type="text"
              placeholder="Enter your secret key here"
            />
          </div>
        </div>
      </div>
      <fieldset className="uk-fieldset">
        <legend className="uk-legend">Request body</legend>
        <div className="uk-margin">
          <input
            value={query}
            onChange={(event) => setQuery(event.target.value)}
            className="uk-input"
            placeholder="Enter a query here"
          />
        </div>
      </fieldset>
      <fieldset className="uk-fieldset">
        <legend className="uk-legend">Signature generation</legend>
        <ul
          uk-tab={true}
          data-uk-tab="{connect:'#signature-generation-examples'}"
        >
          <li>
            <a href="#">Ruby example</a>
          </li>
          <li>
            <a href="#">Node example</a>
          </li>
        </ul>
        <ul
          id="signature-generation-examples"
          className="uk-switcher uk-margin"
        >
          <li>
            <RubyExample secret={secretKey} requestBody={query} />
          </li>
          <li>
            <NodeExample secret={secretKey} requestBody={query} />
          </li>
        </ul>
      </fieldset>

      <fieldset className="uk-fieldset">
        <legend className="uk-legend">Example cURL request</legend>
        <p />

        <div className="language-bash highlighter-rouge">
          <div className="highlight">
            <CurlSample
              url={endpoint}
              appId={appId}
              signature={signature}
              query={query}
            />
          </div>
        </div>
      </fieldset>
    </form>
  );
}
