import gql from "graphql-tag";

export default gql`
  mutation IssueApiKey($input: IssueApiKeyMutationInput!) {
    issueApiKey(input: $input) {
      apiKey {
        id
      }
    }
  }
`;
