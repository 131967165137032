export default `query GetLineItemsEligibility($exampleLineItems: [LineItemInput!]!) {
  lineItemsEligibility(lineItems: $exampleLineItems) {
    id
    isEligible
    estimatedResaleValue {
      cents
      currency
    }
  }
}`;
