import React from "react";

interface Props {
  secret: string;
  requestBody: string;
}

export default function RubyExample({ secret, requestBody }: Props) {
  return (
    <div className="language-ruby highlighter-rouge">
      <div className="highlight">
        <pre className="highlight">
          <code>
            <div>{`require 'base64'`}</div>
            <div>{`require 'openssl'`}</div>
            <div>{` `}</div>
            <div>{`secret_key = '${secret}'`}</div>
            <div>{`request_body = '${requestBody}'`}</div>
            <div>{` `}</div>
            <div>{`signature = Base64.strict_encode64(`}</div>
            <div>{`  OpenSSL::HMAC.digest('sha256', secret_key, request_body)`}</div>
            <div>{`)`}</div>
          </code>
        </pre>
      </div>
    </div>
  );
}
