import React from 'react';

// prettier-ignore
export default function AndroidConfirmationView() {
  return (
    <pre className='highlight'>
      <code className='swift'>
      val airRobeConfirmation = findViewById&lt;AirRobeConfirmation&gt;(R.id.confirmation_widget)<br/>
      ...<br/>
      airRobeConfirmation.initialize(<br/>
      {'  '}orderId = "123456"<br/>
      {'  '}orderSubtotalCents = "11000",<br/>
      {'  '}currency = "AUD",<br/>
      {'  '}email = "test@email.com"<br/>
      )<br/>
      </code>
    </pre>
  )
}
