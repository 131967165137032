import React from 'react';

// prettier-ignore
export default function AndroidOptInView() {
  return (
    <pre className='highlight'>
      <code className='swift'>
      val airRobeOptIn = findViewById&lt;AirRobeOptIn&gt;(R.id.opt_in_widget)<br/>
      ...<br/>
      airRobeOptIn.initialize(<br/>
      {'  '}brand = "",<br/>
      {'  '}material = "",<br/>
      {'  '}category = "Hats/fancy-hats",<br/>
      {'  '}priceCents = "100",<br/>
      {'  '}originalFullPriceCents = "100",<br/>
      {'  '}rrpCents = "100",<br/>
      {'  '}currency = "AUD",<br/>
      {'  '}locale = "en-AU"<br/>
      )<br/>
      </code>
    </pre>
  )
}
