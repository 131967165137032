import React from 'react'

// prettier-ignore
export default function MultiOptInView() {
  return (
    <pre className='highlight'>
      <code className='swift'>
      var airRobeMultiOptIn = AirRobeMultiOptIn()<br/>
      <br/>
      airRobeMultiOptIn.initialize(<br/>
      {'  '}items: [<br/>
      {'  '}{'  '}"Accessories",<br/>
      {'  '}{'  '}"Accessories/Beauty",<br/>
      {'  '}{'  '}"Accessories/Bags/Leather bags/Weekender/Handbags",<br/>
      {'  '}{'  '}"Accessories/Bags/Clutches/Bum Bags"<br/>
      {'  '}]<br/>
      )<br/>
      </code>
    </pre>
  )
}
