import React from "react";

interface Props {
  appId: string;
}

// prettier-ignore
export default function Initialization({
  appId
}: Props) {
  return (
    <pre className='highlight'>
      <code className='swift'>
      AirRobeWidget.initialize(<br/>
      {'  '}config: AirRobeWidgetConfig(<br/>
        {'  '}{'  '}appId: "{`${appId || '...'}`}",<br/>
        {'  '}{'  '}privacyPolicyURL: "https://merchant.com/privacy-policy",<br/>
        {'  '}{'  '}color: "ff0000",<br/>
        {'  '}{'  '}mode: .production<br/>
        {'  '})<br/>
        )
      </code>
    </pre>
  )
}
